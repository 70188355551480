import React  from "react";
import {Text,Heading,Box, SimpleGrid} from '@chakra-ui/react'
import { StaticImage } from "gatsby-plugin-image";
import MeinSeo from '../components/seo'
import {motion} from 'framer-motion'
import Link from '../components/gatsbylink'

export default function Marx(){
    return(
        <motion.div 
        initial={{opacity:0,paddingBottom:"100px"}}
        animate={{
          opacity:1
        }}
        transition={{duration:.1,delay:.1,ease:"circInOut"}}
        >  
        <Box  mt="60px"><Link to="/oberwesel"><Text> &rarr; back</Text></Link></Box>
        <SimpleGrid pt="60px" gap={4} columns={[1,1,2]}>
            <MeinSeo title="Das Schicksal der jüdischen Familie Marx aus Oberwesel" description="Bilder und Dokumente zum Schicksal der Familie Marx aus Oberwesel" />
        <Box>
            <Heading variant="utitel" pt="0">
            Pliersgasse 5<br/>
            Herta und Lina Marx – Julius und Paula Marx mit Ruth<br/>
            Else Abraham, geb. Marx, und ihre Töchter Bertel und Lore
            </Heading>
            
            <Text variant="solid">
            In der Pliersgasse 5 wohnten der um 1900 aus Geinsheim in der Vorderpfalz zugezogene Moritz Marx (*1866–1938) und seine in Oberwesel geborene Frau Klara, geb. Simon (1866–1935). Sie führten ein Kolonialwaren- und Tabakwarengeschäft. Ihre Kinder sind hier geboren: Martha (*1886), Julius (*1892), Lina (*1897), Else (*1899) und Herta (*1905). Zur Einweihung der neuen Synagoge wurde 1886 von diesem Hause aus die Thora zum Schaarplatz getragen. Mathilde Gerson (später verheiratete Aumann) trug den Schlüssel. Lina und Herta blieben unverheiratet. Ihr Bruder Julius heiratete Paula Cahn (*1893) und machte in Bettingen im Saargebiet ein Konfektionsgeschäft auf. Ihre Tochter hieß Ruth (*1922). Martha heiratete den Bäcker Louis Gottschalk und zog nach Werlau, sie hatten drei Kinder. Else heiratete den Kaufmann Leo Abraham und zog nach Altenkirchen. Ihre Töchter Bertel (*1928) und Lore (*1930) waren Spielkameradinnen von Ruth Lichtenstein.
            </Text>
            <Heading variant="utitel">1933–1937</Heading>
            <Text variant="solid">Leo Abraham plant die Flucht nach Palästina, die infolge eines eines  Verkehrsunfalls 1937 vereitelt werden.</Text>
            <Heading variant="utitel">22. September 1936</Heading>
            <Text variant="solid">erta Marx, geboren am 14.02.1905, zieht nach Mainz, wo sie als Kontoristin bis 05.07. 1938 in Stellung ist und dann zurückkehrt. Sie bemüht sich um die zur Flucht nötigen Papiere.
</Text>
            <Heading variant="utitel">Frühsommer 1938</Heading>
            <Text variant="solid">Martha und Louis Gottschalk fliehen mit den Kindern Kurt (*1922), Margot (*1924) und Else (*1925) nach Argentinien. Louis stirbt am 03.11.1968, Martha am 26.11.1983. Else heiratet Heinz Zwicknapp-Mello, der 1958 stirbt. Ihre Tochter Renate Hart lebt in Michigan. Else heiratet in zweiter Ehe Juan Frischmann. Margot heiratet Willi Kirsch, ihre Kinder sind Else und Ricardo. Bei einem Autounfall kommen Margot, Willi und Ricardo 1978 ums Leben.</Text>
            <Heading variant="utitel">November 1938</Heading>
            <Text variant="solid">Martha und Louis Gottschalk fliehen mit den Kindern Kurt (*1922), Margot (*1924) und Else (*1925) nach Argentinien. Louis stirbt am 03.11.1968, Martha am 26.11.1983. Else heiratet Heinz Zwicknapp-Mello, der 1958 stirbt. Ihre Tochter Renate Hart lebt in Michigan. Else heiratet in zweiter Ehe Juan Frischmann. Margot heiratet Willi Kirsch, ihre Kinder sind Else und Ricardo. Bei einem Autounfall kommen Margot, Willi und Ricardo 1978 ums Leben.</Text>
            <Heading variant="utitel">November 1938</Heading>
            <Text variant="solid">Julius und Paula Marx werden aus Bettingen vertrieben. In der Pogromnacht demolieren SA-Männer das Haus und schmieren Parolen. Julius wird verhaftet und ins KZ Dachau gebracht. Am 09.11. überfallen SA-Männer die Frauen, zwingen sie Rizinusöl zu trinken, alles wird durchwühlt. Am nächsten Morgen müssen die Frauen Bettingen verlassen. In Saarbrücken kommen sie bei Verwandten unter. In Altenkirchen wird Elses Mann Leo Abraham verhaftet und nach Dachau verbracht. Er wird gezwungen, seinen Besitz zum halben Preis zu verkaufen.</Text>
            <Heading variant="utitel">17. Februar 1939</Heading>
            <Text variant="solid">Ruth Marx wird nach Bad Nauheim abgemeldet, wo sie bei Verwandten unterkommt. Mit einem Kindertransport gelangt sie über Hoek van Holland nach Birmingham. Während des Krieges arbeitet sie dort als Kindermädchen. Sie heiratet 1945 Raymond Jacobs und wandert mit ihm nach Kanada aus, wo sie 1984 in Hamilton, Ontario, stirbt.</Text>
            <Heading variant="utitel">31. Juli 1939</Heading>
            <Text variant="solid">Leo Abraham flieht alleine nach England und wartet im Kitchener Camp in Sandwich auf die Überfahrt nach New York. Er versucht, Else und die Kinder nachzuholen, die jetzt in Köln leben.</Text>
            <Heading variant="utitel"> 4. September 1939</Heading>
            <Text variant="solid">Julius und Paula Marx ziehen bei der Freimachung des Saargebiets nach Oberwesel. Zum Haushalt gehört auch Paulas Mutter Henriette Cahn (✡1942).</Text>
            <Heading variant="utitel">7. Dezember 1941</Heading>
            <Text variant="solid">Else Abraham wird zusammen mit ihren Töchtern Bertel und Lore vom Bahnhof Deutz-Tief aus ins Ghetto Riga deportiert. Sie werden vermutlich 1942 bei der »Aktion Dünamünde« ermordet. Leo ist lebenslang psychisch krank. Er stirbt am 14.08.1980.</Text>
            <Heading variant="utitel">2. April 1942</Heading>
            <Text variant="solid">Paula und Julius Marx und seine Schwestern Lina und Herta werden nach Salzig in das Hotel Schwan gebracht. Am Vorabend hat Julius dem Bäcker Josef Henrich noch die Bücher gemacht. Am 30.04. werden sie von Güterbahnhof Koblenz-Lützel ins Ghetto Krasniczyn transportiert</Text>
         
            </Box>
            <Box>
                <Box p="1">
                    <StaticImage src="../images/solo/Herta-Marx.jpg" />
                    <Text variant="Bild">Herta Marx</Text>
                </Box>
               
                <Box p="1">
                    <StaticImage src="../images/solo/Lina-Marx.jpg" />
                    <Text variant="Bild">Lina Marx</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/rio.jpg" />
                    <Text variant="Bild">Kurt, Martha geb. Marx, Elsie, Margot und Louis Gottschalk 1937 in Rio de Janeiro</Text>
                </Box>
                <Box p="1">
                    <StaticImage src="../images/solo/uss-manhatten.jpg" />
                    <Text variant="Bild">Schiffskarte</Text>
                </Box>
            
            </Box>
            </SimpleGrid>
            </motion.div>
    )
}